@keyframes animation_walmartrealmlogo_4_flow_1 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes animation_walmartrealmlogo_4_flow_3 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.66;
  }
}

@keyframes animation_walmartrealmlogo_4_flow_4 {
  from {
    opacity: 0.66;
  }

  to {
    opacity: 0;
  }
}

@keyframes animation_Background_2_flow_1 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes animation_Background_1_flow_1 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes animation_Background_1_flow_2 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes animation_Background_7_flow_2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes animation_Background_7_flow_3 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes animation_Splash_F1_0_0_flow_3 {
  from {
    fill: #0141C2;
  }

  to {
    fill: black;
  }
}

#walmartrealmlogo_4 {
  animation: animation_walmartrealmlogo_4_flow_1 0.3s ease-in 0.15s forwards,
    animation_walmartrealmlogo_4_flow_3 0.3s ease-in 1.55s forwards,
    animation_walmartrealmlogo_4_flow_4 0.8s ease-out 1.85s forwards;
}

#Background_2 {
  animation: animation_Background_2_flow_1 0.5s ease-in 0.15s forwards;
}

#Background_1 {
  animation: animation_Background_1_flow_1 0.3s ease-in 0.15s forwards,
    animation_Background_1_flow_2 0.8s ease-out 0.45s forwards;
}

#Background_7 {
  animation: animation_Background_7_flow_2 0.8s ease-out 0.45s forwards,
    animation_Background_7_flow_3 0.3s ease-in 1.55s forwards;
}

#Splash_F1_0_0 {
  animation: animation_Splash_F1_0_0_flow_3 0.3s ease-in 1.55s forwards;
}